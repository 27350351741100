export const UserRoles = {
  ROLE_USER_ADMIN: 'ROLE_USER_ADMIN',
  ROLE_OPERATOR: 'ROLE_OPERATOR',
  ROLE_NORMAL: 'ROLE_NORMAL',
  ROLE_DIGITAL_REP: 'ROLE_DIGITAL_REP',
  ROLE_FIELD_REP: 'ROLE_FIELD_REP',
  ROLE_STAFF: 'ROLE_STAFF',
} as const

export type UserRoles = (typeof UserRoles)[keyof typeof UserRoles]
