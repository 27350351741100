import { SystemAccount } from 'domains/account'
import { Address } from 'domains/address'
import { EmptyAPIResponse } from 'types/APIResponses'
import { parseFileToForm } from 'utils/parseFileToFormData'

import { fileApi, protectedApi } from './requester'

export const getAccountSearchResults = (search: string) => {
  return protectedApi.get<SystemAccount>(`accounts/${search}`).then((response) => response.data)
}

interface UpdateAccountParamsOptions {
  username: string
  salesRep?: string
  fieldRep?: string
  territoryId?: string
  adjustment?: string
}

export const updateAccountParams = ({ username, ...params }: UpdateAccountParamsOptions) => {
  return protectedApi.put<EmptyAPIResponse>(
    `/accounts/${username}`,
    {},
    {
      params,
    },
  )
}

interface ImportAccountDSSOptions {
  importDSSApply: boolean
  file: File
}

export const importAccountDSS = ({ importDSSApply, file }: ImportAccountDSSOptions) => {
  return fileApi
    .post<string>(`/accounts/dss?commit=${importDSSApply}`, parseFileToForm(file))
    .then((response) => response.data)
}

interface ImportAccountOFROptions {
  importOFRApply: boolean
  file: File
}

export const importAccountOFR = ({ importOFRApply, file }: ImportAccountOFROptions) => {
  return fileApi
    .post<string>(`/accounts/ofr?commit=${importOFRApply}`, parseFileToForm(file))
    .then((response) => response.data)
}

export const getAccountShipAddresses = (username: string) => {
  return protectedApi
    .get<Address[]>(`/accounts/${username}/addresses`)
    .then((response) => response.data)
}

interface UpdateAccountAddressExternalIdOptions {
  id: number
  username: string
  externalId: string
}

export const updateAccountAddressExternalId = ({
  id,
  externalId,
  username,
}: UpdateAccountAddressExternalIdOptions) => {
  return protectedApi
    .put<Address[]>(`/accounts/${username}/address/${id}?externalId=${externalId ?? null}`)
    .then((response) => response.data)
}

interface ImportAccountAddressOptions {
  username: string
  file: File
}

export const importAccountAddress = ({ username, file }: ImportAccountAddressOptions) => {
  return fileApi
    .post<string>(`/accounts/${username}/importAddresses`, parseFileToForm(file))
    .then((response) => response.data)
}

export const exportAccountAddress = (username: string): Promise<Blob> => {
  return fileApi
    .get(`/accounts/${username}/exportAddresses`, { responseType: 'blob' })
    .then((response) => response.data)
}
